<script setup lang="ts">
definePageMeta({ layout: 'default' })

useHead({
  bodyAttrs: {
    class: 'bg-zinc-50 dark:bg-inj-black dark:text-white overflow-x-hidden'
  }
})

function onRedirectHome() {
  navigateTo('/')
}
</script>
<template>
  <div class="h-screen">
    <NuxtLayout>
      <section class="h-full-flex h-full">
        <h2 class="text-3xl font-semibold">{{ $t('notFound404.title') }}</h2>

        <UCard class="mt-6">
          <p>{{ $t('notFound404.description') }}</p>
          <AppButton class="mt-6" @click="onRedirectHome">
            {{ $t('notFound404.backToHome') }}
          </AppButton>
        </UCard>
      </section>
    </NuxtLayout>
  </div>
</template>
