import { BurnAuctionHistoryTableColumn } from '@/types'

export default {
  auction: {
    table: {
      history: {
        [BurnAuctionHistoryTableColumn.Winner]: 'Winner',
        [BurnAuctionHistoryTableColumn.Auction]: 'Auction',
        [BurnAuctionHistoryTableColumn.AmountBurnt]: 'Amount burnt'
      }
    },
    title: 'Burn Auction',
    about: 'About Injective Burn Auction',
    description:
      'INJ powers Injective and a new era of finance. Transactions across the Injective ecosystem are gathered into a single basket and auctioned off weekly. The highest bidder claims the basket, and the payment in INJ is immediately burned, permanently removing those tokens from circulation. This weekly process reduces the token supply, reinforcing Injective’s deflationary design and enhancing its long-term value.',

    round: 'Round #{round}',
    endsAt: 'Auction ends at',
    endedOn: 'Auction ended on',
    currentRound: 'Current Round #{round}',
    burnAuctionHistory: 'Burn Auction History',
    goToCurrentAuction: 'Go to current auction',
    estimatedTotalValue: 'Estimated Total Value',

    banner: {
      title: 'Burn Auction',
      subTitle:
        'Injective burns 60% of all exchange fees every week through an auction process.',
      link: 'Burn Auction'
    },

    basket: {
      amount: 'Amount',
      estValue: 'Estimated value',
      token: 'Token',
      empty: 'No trading fees were collected for this round',
      rollover: 'Tokens added to the next burn',
      injInAuction:
        'INJ fees generated from dApps is included in the auction basket now for easier tracking. Its also capped (parameter defined by governance) to make bidding accessible to everyone in the community.'
    },

    common: {
      nav: 'Auction',
      statusLive: 'Live now',
      statusClosed: 'Closed',
      round: 'Round #{round}',
      roundI18nt: 'Round #{round}'
    },

    history: {
      title: 'Auction History',
      totalBurned: 'Total amount burnt',
      totalBurnedTooltip: 'The amount of INJ burnt to date',
      auction: 'Auction',
      amountBurnt: 'Amount burnt',
      injRound: 'Round #{round}',
      winner: 'Winner',
      date: 'Date',
      noWinner: 'No winner',
      viewDetail: 'Details',
      showMore: 'Show More'
    },

    info: {
      title: 'Auction Info',
      estTotalValue: 'Estimated Total Value',
      totalValue: 'Total Value',
      auctionEndsAt: 'Auction ends at',
      auctionEndedOn: 'Auction ended on',
      auctionRoundTooltip:
        'Trading fees collected by Injective Protocol between {range}'
    },

    bid: {
      title: 'Place a bid',
      placeABid: 'Place a bid',
      addToNextAuctionPool: 'Add to Next Auction Pool',
      titleConfirm: 'Are you sure?',
      myInjBalance: 'My inj balance',
      myBid: 'My bid',
      subTotal: 'Subtotal',
      total: 'Total',
      confirm: 'Confirm',
      ok: 'OK',
      goBack: 'Go back',
      confirmTitle: 'Are you sure you want to place a bid?',
      confirmHighestBidderHeader: 'You are already the highest bidder!',
      confirmBody:
        "Once you place the bid, you won't be able to cancel the bid.",
      submittedHeader: 'You have placed a bid.',
      submittedBody: 'The auction will end at {endingDate}.',
      successBid: 'Successfully Bid'
    },

    bidsHistory: {
      title: 'Bids History',
      amount: 'Amount',
      assets: 'Assets',
      bidPlacedBy: 'Bid placed by',
      currentHighestBid: 'Current Highest Bid',
      empty: 'No bids have been submitted yet. Be the first to bid!',
      emptyHistorical: 'No bids were submitted for this auction.',
      finalBid: 'Final Bid',
      injAddress: 'Injective address',
      noBidsYet: 'No Bids yet',
      noWinner: 'No winner',
      timestamp: 'Timestamp'
    },

    status: {
      placeABid: 'Place a bid',
      closingIn: 'Closing in'
    },

    ticket: {
      title: 'Congratulations! You won.',
      winnerRound: 'You are the winner of INJ Burn #{round}.',
      totalValue: 'Total Value',
      yourBid: 'Your Bid',
      ok: 'OK'
    },

    addToPool: {
      title: 'Add to Next Auction Pool',
      asset: 'Asset',
      amount: 'Amount',
      enterAmount: 'Enter amount',
      viewTransaction: 'View Transaction',
      availableBalance: 'Available balance',
      assetToAdd: 'Asset to add to the next pool',
      successfullyAdded: 'You have added {asset} to the #{round} auction.'
    }
  }
}
