import {
  BigNumber,
  BigNumberInBase,
  BigNumberInWei
} from '@injectivelabs/utils'

export * from './setup'

// eslint-disable-next-line prefer-regex-literals
export const NUMBER_REGEX = new RegExp(/^-?(0|[1-9]\d*)?(\.\d+)?$/)

export const DEFAULT_DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm:ss'
export const MINIMUM_USDT_DEPOSIT_FOR_INSURANCE_FUNDS = 20000
export const TIME_AGO_DATE_FORMAT = "MMM dd yyyy HH:mm:ss 'UTC'xxx"
export const UNLIMITED_ALLOWANCE = new BigNumberInWei(2).pow(256).minus(1)

// 6 gwei for Kovan, fetched from gasStation for Mainnet
export const GWEI_IN_WEI: BigNumber = new BigNumber(1000000000)
export const DEFAULT_GAS_PRICE = new BigNumber(6).times(GWEI_IN_WEI)

export const UI_BASIS_POINT_DECIMAL_PLACES = 2
export const UI_DEFAULT_USD_DECIMAL_PLACES = 2
export const UI_MAX_TRAILING_ZEROS = 3
export const UI_MINIMAL_DECIMAL_PLACES = 4
export const UI_DEFAULT_DECIMAL_PLACES = 8
export const UI_MINIMAL_AMOUNT = new BigNumber(1).shiftedBy(
  -UI_MINIMAL_DECIMAL_PLACES
)
export const UI_DEFAULT_MINIMAL_AMOUNT = new BigNumber(1).shiftedBy(
  -UI_DEFAULT_DECIMAL_PLACES
)
export const BIG_NUMBER_ROUND_DOWN_MODE = BigNumberInBase.ROUND_DOWN
export const BIG_NUMBER_ROUND_UP_MODE = BigNumberInBase.ROUND_UP

export const ZERO_IN_WEI = new BigNumberInWei(0)

export const INJECTIVE_VALIDATOR_NODE_NAME = 'Injective Node'
export const USDC_COIN_GECKO_ID = 'usd-coin'
export const USDT_COIN_GECKO_ID = 'tether'
export const ETH_COIN_GECKO_ID = 'ethereum'
export const UST_COIN_GECKO_ID = 'terrausd'
export const INJ_FEE_BUFFER = 0.005
export const GAS_LIMIT_MULTIPLIER = 1.2
export const GOVERNANCE_INSTANT_LAUNCH_FEE = 20
export const GOVERNANCE_MIN_DEPOSIT = 50
export const GOVERNANCE_MAX_DEPOSIT = 100
export const INJ_REQUIRED_FOR_GAS = 0.005
export const INJ_DECIMAL_PLACES = 18

export const ZERO_ADDRESS = 'inj1qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqe2hm49'
export const AUCTION_POOL_SUBACCOUNT_ID =
  '0x1111111111111111111111111111111111111111111111111111111111111111'

export const VOTING_POWER_PERCENTAGE = 5 // 5%
export const DEFAULT_TRUNCATE_LENGTH = 6

export const UI_AMOUNT_ABBREVIATION_FLOOR = 1_000_000
