import {
  StateTree,
  PiniaPluginContext,
  SubscriptionCallback,
  SubscriptionCallbackMutationPatchObject
} from 'pinia'
import { Wallet } from '@injectivelabs/wallet-base'
import { StatusType } from '@injectivelabs/utils'
import { isThrownException, ThrownException } from '@injectivelabs/exceptions'
import { defineNuxtPlugin } from '#imports'
import { localStorage } from '@/app/Service'
import { currencies } from '@/app/region/currencies'

const stateToPersist = {
  app: {
    currency: currencies.USD,
    theme: '',
    userState: {
      modalsViewed: [],
      termsAccepted: false
    }
  },
  sharedGeo: {
    geoCountry: '',
    geoContinent: ''
  },
  auction: {
    ticketsShown: ''
  },
  sharedWallet: {
    wallet: Wallet.Metamask,
    session: '',
    address: '',
    addresses: '',
    injectiveAddress: '',
    addressConfirmation: ''
  }
} as Record<string, Record<string, any>>

const actionsThatSetAppStateToBusy = [
  'governance/voteToProposal',
  'governance/depositToProposal',
  'governance/proposeSpotMarketLaunch',
  'governance/proposePerpetualMarketLaunch',
  'governance/launchInstantSpotMarket',
  'governance/proposeTextProposal',
  'staking/delegate',
  'staking/delegateToValidator',
  'staking/reDelegate',
  'staking/unbond',
  'staking/claimRewards',
  'staking/claimAllRewards',
  'staking/claimRewardsFromValidator',
  'staking/unbondFromValidator',
  'staking/cancelUnbondingDelegation',
  'insuranceFund/underwrite',
  'insuranceFund/requestRedemption',
  'insuranceFund/createInsuranceFund',
  'auction/bid',
  'bank/transfer',
  'bank/transferCw20ToBank',
  'bank/redeemFromBankToCw20',
  'ibc/transferFromInjective',
  'ibc/transferToInjective',
  'insuranceFund/underwrite',
  'insuranceFund/requestRedemption',
  'peggy/setTokenAllowance',
  'peggy/transfer',
  'peggy/withdraw',
  'tokenFactory/mintSupply',
  'tokenFactory/burnSupply',
  'tokenFactory/createDenom',
  'tokenFactory/updateDenomMetadata'
]

const actionsThatThrowErrors = ['token/getTokensUsdPriceMap']

const persistState = (
  mutation: SubscriptionCallbackMutationPatchObject<StateTree>,
  state: StateTree
) => {
  if (!stateToPersist[mutation.storeId]) {
    return
  }

  const keysToPersist = Object.keys(stateToPersist[mutation.storeId])
  const shouldPersistState =
    keysToPersist.length > 0 &&
    Object.keys(mutation.payload || []).some((key) => {
      return keysToPersist.includes(key)
    })

  if (!shouldPersistState) {
    return
  }

  const updatedState = keysToPersist.reduce((stateObj, key) => {
    return {
      ...stateObj,
      [key]: mutation.payload[key] || state[key]
    }
  }, {})

  const existingState = (localStorage.get('state') || {}) as any

  localStorage.set('state', {
    ...stateToPersist,
    ...existingState,
    [mutation.storeId]: {
      ...updatedState
    }
  })
}

function piniaStoreSubscriber({ store }: PiniaPluginContext) {
  const localState = localStorage.get('state') as any
  const sharedWalletStore = useSharedWalletStore()
  const { $onError } = useNuxtApp()

  if (localState[store.$id]) {
    store.$state = { ...store.$state, ...localState[store.$id] }
  }

  store.$subscribe(persistState as SubscriptionCallback<StateTree>)

  store.$onAction(({ name, store: { $id }, after, onError }) => {
    after(() => {
      const type = `${$id}/${name}`

      if (actionsThatSetAppStateToBusy.includes(type)) {
        sharedWalletStore.$patch({
          queueStatus: StatusType.Idle
        })
      }
    })

    onError((error) => {
      const type = `${$id}/${name}`

      if (actionsThatSetAppStateToBusy.includes(type)) {
        sharedWalletStore.$patch({
          queueStatus: StatusType.Idle
        })
      }

      if (
        actionsThatThrowErrors.includes(type) &&
        isThrownException(error as Error)
      ) {
        $onError(error as unknown as ThrownException)
      }
    })
  }, true)
}

export default defineNuxtPlugin(
  ({
    vueApp: {
      config: { globalProperties }
    }
  }) => {
    globalProperties.$pinia.use(piniaStoreSubscriber)
  }
)
