import {
  MyStakingTableColumn,
  UnstakeRestakeTableColumn,
  ValidatorDelegationsTableColumn,
  StakeOverviewValidatorsTableColumn
} from '@/types'

export default {
  staking: {
    table: {
      validatorDelegations: {
        [ValidatorDelegationsTableColumn.Delegator]: 'Delegator',
        [ValidatorDelegationsTableColumn.Percentage]: 'Percentage',
        [ValidatorDelegationsTableColumn.StakedAmount]: 'Staked Amount',
        [ValidatorDelegationsTableColumn.CommissionRate]: 'Commission Rate'
      },
      overviewValidators: {
        [StakeOverviewValidatorsTableColumn.Uptime]: 'Uptime',
        [StakeOverviewValidatorsTableColumn.Validator]: 'Validator',
        [StakeOverviewValidatorsTableColumn.Commission]: 'Commission',
        [StakeOverviewValidatorsTableColumn.VotingPower]: 'Voting Power',
        [StakeOverviewValidatorsTableColumn.StakedAmount]: 'Staked Amount'
      },
      unstakeRestake: {
        [UnstakeRestakeTableColumn.Amount]: 'Amount',
        [UnstakeRestakeTableColumn.Status]: 'Status',
        [UnstakeRestakeTableColumn.Delegations]: 'Delegations',
        [UnstakeRestakeTableColumn.CompletionTime]: 'Completion Time'
      },
      myStaking: {
        [MyStakingTableColumn.Commission]: 'Commission',
        [MyStakingTableColumn.Claimable]: 'Claimable',
        [MyStakingTableColumn.Delegations]: 'Delegations',
        [MyStakingTableColumn.StakedAmount]: 'Staked Amount'
      }
    },
    claim: 'Claim',
    stake: 'Stake',
    staked: 'Staked',
    active: 'Active',
    signed: 'Signed',
    missed: 'Missed',
    uptime: 'Uptime',
    uptimeTooltip: 'Uptime percentage based on the latest 10k blocks.',
    staking: 'Staking',
    address: 'Address',
    unstake: 'Unstake',
    restake: 'Restake',
    roi: 'ROI 365 Days',
    website: 'Website',
    proposed: 'Proposed',
    inactive: 'Inactive',
    overview: 'Overview',
    claimAll: 'Claim All',
    stakeInj: 'Stake INJ',
    stakeNow: 'Stake Now',
    validator: 'Validator',
    showMore: 'Show More',
    delegator: 'Delegator',
    claimable: 'Claimable',
    myStaking: 'My Staking',
    delegators: 'Delegators',
    commission: 'Commission',
    activities: 'Activities',
    percentage: 'Percentage',
    validators: 'Validators',
    Delegators: 'Delegators',
    unstakeInj: 'Unstake INJ',
    description: 'Description',
    delegations: 'Delegations',
    totalStaked: 'Total Staked',
    stakeAmount: 'Stake Amount',
    votingPower: 'Voting Power',
    stakedAmount: 'Staked Amount',
    dayRewards: '{days}d Rewards',
    backToStaking: 'Back to Staking',
    commissionRate: 'Commission Rate',
    claimAndRestake: 'Claim & Restake',
    totalStakedInj: 'Total Staked INJ',
    noOfValidators: 'No. of Validators',
    securityContact: 'Security Contact',
    completitionTime: 'Completion Time',
    totalDelegators: 'Total Delegators',
    myStakingAmount: 'My Staking Amount',
    claimableRewards: 'Claimable Rewards',
    validatorDetails: 'Validator Details',
    selectAValidator: 'Select a Validator',
    yourStakingStats: 'Your Staking Stats',
    'unstake-restake': 'Unstake & Restake',
    yourStakedAmount: 'Your Staked Amount',
    selectedValidator: 'Selected Validator',
    stakingRewardsApr: 'Staking Rewards APR',
    totalStakingValue: 'Total Staking Value',
    stakedSuccessfully: 'Staked Successfully',
    noDelegationsFound: 'No Delegations Found',
    unstakedSuccessfully: 'Unstaked Successfully',
    estimatedStakingRewards: 'Estimated Staking Rewards',
    redelegateFromValidator: 'Redelegate from Validator',
    enterYourAmountToStake: 'Enter your amount to stake',
    enterYourAmountToUnstake: 'Enter your amount to unstake',
    successfullyClaimedAndRestaked: 'Successfully Claimed and Restaked',
    enterStakingAmount: 'Enter staking amount to view estimated future rewards',
    unstakingTerms:
      'I understand unstaking process will take 21 days and I will not be able to use my INJ during this period.',
    validatorDelegations: 'Validator Delegations',
    youHaveStaked: 'You have staked {amount} INJ',
    youHaveUnstaked: 'You have unstaked {amount} INJ',
    stakingTerms:
      'I understand unstaking process will take 21 days for INJ to become liquid upon withdrawal.',
    estimatedStakingAwards: 'Estimated Staking Awards',
    availableInjInWallet: 'Available INJ In Wallet',
    unbondingsRedelegate: 'Unbondings/Redelegate',
    continueToStaking: 'Continue to Staking',
    completionTime: 'Completion Time',
    decentralizationWarningTitle:
      'Distributed voting power is the key to a decentralized network',
    decentralizationIsACollaborativeEffort:
      'Decentralization is a collaborative effort',
    decentralizationWarningMessage:
      'When there are more diverse group of validators with distributed voting powers, the network is more decentralized and secure. You have selected a validator with relatively high voting power, by choosing another validator, you will contribute to making Injective more decentralized.',
    validatorVotingPowerWarning:
      'You have selected a validator with relatively large voting power. In order to make Injective more decentralized, please consider choosing another validator.',
    chooseOtherValidators: 'Choose other validators',
    selectAnotherValidator: 'Select another validator',
    continueToStake: 'Continue to stake',
    continueToDelegate: 'Continue to delegate',
    viewYourStaking: 'View and manage your staking by connecting your wallet.',
    connectWallet: 'Connect Wallet',
    commissionRateTitle: '100% Commission rate = No Rewards',
    stakeAnyway: 'Stake Anyway (and receive no staking rewards)',

    recent100Blocks: 'Recent 100 Blocks',
    calculateRewards: 'Calculate Rewards',
    rewardsCalculator: 'Staking Rewards Calculator',
    apr: 'apr',
    rewards: 'Staking Rewards',
    delegationWarning: 'Delegation Warning',
    caution: 'Caution',
    noRewards: 'no rewards',
    cancelUnbond: 'Cancel Unbond',
    successfullyCancelledUnbond: 'Successfully Cancelled Unbond',
    delegationWarningMessage:
      'Please be advised that delegating to a validator with a 100% commission rate will result in {noRewards} being issued to you. If you delegate to a 100% commission rate validator, you will receive {noRewards}. As a result, we strongly advise against delegating to such validators.',
    delegateAnyway: 'Delegate Anyway',
    dailyReturns: 'Daily Returns',
    monthlyReturns: 'Monthly Returns',
    yearlyReturns: 'Yearly Returns',
    yourStake: 'Your Injective (INJ) Stake',
    searchForValidators: 'Search for Validators',
    stakingOverview: 'Your Staking Overview',
    validatorsLimitReached:
      'Please be aware that you are now delegating to more than 10 validators. Certain features in the Injective ecosystem will only account for staked INJ in the first 10 validators.',
    goToStaking: 'Go To Staking',
    successfullyClaimedRewards: 'Successfully Claimed Rewards'
  }
}
