export default {
  governance: {
    title: 'Governance',
    description: 'Description',
    searchForProposals: 'Search for Proposals',

    totalProposals: 'Total Proposals',
    passedProposals: 'Passed Proposals',
    depositRequired: 'Deposit Required',

    common: {
      deposit: 'Deposit',
      deposits: 'Deposits'
    },

    proposal: {
      fee: 'Fee',
      spotInstantLaunchFeeTooltip:
        'The fee required to launch a spot market pair instantly',
      insuranceFundTickerTooltip:
        'The ticker for the insurance fund that will underwrite your derivative proposal.',
      initialInsuranceFundDepositTooltip:
        'The initial deposit requirement for the insurance fund.',
      minimumProposalDepositRequired:
        'You need minimum 1 INJ in your balance to make a proposal',
      successfullyProposed: 'Successfully Proposed',

      insufficientBalance: 'Insufficient Balance',
      successfullyLaunched: 'Successfully Launched'
    },

    types: {
      'spot-market-launch': 'Spot Market Launch',
      'perpetual-market-launch': 'Perpetual Market Launch',
      'instant-spot-market-launch': 'Instant Spot Market Launch',
      'text-proposal': 'Text Proposal',

      'oracle-type-1': 'Band',
      'oracle-type-2': 'Pricefeed',
      'oracle-type-3': 'Coinbase',
      'oracle-type-4': 'Chainlink',
      'oracle-type-5': 'Razor',
      'oracle-type-6': 'DIA',
      'oracle-type-7': 'API3',
      'oracle-type-8': 'UMA',
      'oracle-type-9': 'Pyth',
      'oracle-type-10': 'Band IBC'
    },

    expedite: 'Expedite'
  },
  discussionForum: 'Discussion Forum'
}
